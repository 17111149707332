@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  h4 {
    @apply text-base;
  }

  h5 {
    @apply text-sm;
  }

  h6 {
    @apply text-xs;
  }
}

.html-res ol {
  list-style: disc;
  padding-left: 1.5rem;
}

.html-res ul {
  list-style: disc;
  padding-left: 1.5rem;
}

.html-res li {
  margin-bottom: 1rem;
}

.html-res a {
  text-decoration: underline;
  color: #2e78e7;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.inter-regular {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.inter-regular {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.inter-bold {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.inter-regular-italic {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.inter-bold-italic {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-style: italic;
}

@layer base {
  @font-face {
    font-family: 'Gotham-Thin';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/src/assets/fonts/Gotham-Font/Gotham-Thin.otf) format('opentype');
  }
}

.article-shadow {
  box-shadow: 0px 14px 20px 0px rgba(0, 0, 0, 0.10);
}

.swiper-pagination-bullet {
  transition: all;
  transition-duration: 0.3s;
  width: 10px !important;
  height: 10px !important;
  border-radius: 10px !important;
  background: #2E388F !important;
}

.swiper-pagination-bullet-active {
  transition: all;
  transition-duration: 0.3s;
  width: 30px !important;
  height: 10px !important;
  border-radius: 10px !important;
  background: #2E388F !important;
  opacity: 1 !important;

}


.swiper-pagination {
  position: relative !important;
  margin-top: 20px !important;
}

.background-linear-grad {
  background: linear-gradient(229deg, #2E388F 0%, #FFF 23.07%);
}

.background-linear-grad2 {
  background: linear-gradient(198deg, #2E3192 -18.25%, #FFF 15.85%);
}

.footer-grad {
  background: linear-gradient(270deg, #231E54 0.22%, #2E3192 48.36%, #231E54 99.68%);
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(-10%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.animate-bounce-custom {
  animation: bounce 1s infinite;
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}

.about-card-shadow {
  box-shadow: 0px 16px 20px 0px rgba(0, 0, 0, 0.10);
}

.ReactModal__Overlay {
  display: flex;
  justify-content:center;
  align-items: center;
}
